<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  placeholder: {
    type: String,
  },
  additionalClasses: {
    type: String,
    default: "",
  },
  variant: {
    type:String,
    default:"no_outline"
  }
});

const mergedClasses = computed(() => {
  const baseClasses =
    "!rounded-xs !ring-0 !bg-white";
  return `${baseClasses} ${props.additionalClasses}`.trim();
});
</script>

<template>
  <UInput
    color="default"
    :variant="variant"
    size="xl"
    :type="type"
    :placeholder="placeholder"
    :model-value="modelValue"
    :input-class="mergedClasses"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<style scoped></style>
